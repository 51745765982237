import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Api from "service/api";
const currentYear = new Date().getFullYear().toString();
const useHolerite = () => {
  const [transitionIn, setTransitionIn] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [fieldErrors, setFieldErrors] = useState([]);
  const [messageAlertDownload, setMessageAlertDownload] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [contentModal, setContentModal] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [ano, setAno] = useState(currentYear);
  const [listHolerites, setListHolerites] = useState([]);
  const { t: translate } = useTranslation();

  document.title = translate("informeRendimento.head.title");

  const listAnos = [
    { label: "2024", value: "2024" },
    { label: "2023", value: "2023" },
  ];

  const handleChangeAno = async (event) => {
    clearViewPdf();
    setOpenMessage(false);
    setAno(event.target.value);
    setFieldErrors(fieldErrors.filter((inp) => inp.name !== "ano"));
    clearViewPdf();
    await getHolerites(event.target.value);
  };

  const clearViewPdf = () => {
    setListHolerites([]);
  };

  const handleOpenModal = (urlFile) => {
    setOpenModal(true);
    setContentModal({
      urlPdf: urlFile,
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setContentModal({});
    setModalType("");
  };

  const getHolerites = async (anoSelected = null) => {
    setLoadingForm(true);
    if (!anoSelected) {
      anoSelected = ano;
    }
    try {
      const endPoint = `informe-rendimento/listar/${anoSelected}`;
      const response = await Api.get(endPoint);
      if (response.data.informes.empty) {
        setMessageAlertDownload(response.data.informes.empty);
        setOpenMessage(true);
        setLoadingForm(false);
        return;
      }

      if (response.data.informes.length > 0) {
        const listData = response.data.informes.map((item) => {
          return item;
        });
        setListHolerites(listData);
      }

      setTimeout(() => {
        setOpenMessage(false);
      }, 3000);
    } catch (error) {
      console.error("Erro ao consultar o informe de rendimentos:", error.message);
    }
    setLoadingForm(false);
  };

  useEffect(() => {
    (async () => {
      await getHolerites(ano);
    })();
  }, []);

  setTimeout(() => {
    setTransitionIn(true);
  }, 300);

  return {
    transitionIn,
    listAnos,
    listHolerites,
    handleChangeAno,
    loadingForm,
    fieldErrors,
    messageAlertDownload,
    openMessage,
    translate,
    ano,
    handleOpenModal,
    handleCloseModal,
    contentModal,
    openModal,
    modalType
  };
};

export default useHolerite;
